.modal-video {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 71.35vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.6);
  border-radius: 5px;
  padding: 16px;
  transition: height 0.3s ease-in-out;
}

.modal-video > iframe {
  width: 100%;
  height: 100%;
}

.modal-video > video {
  width: 100%;
  height: auto;
}

.modal-video > p {
  width: 100%;
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 100%;
  color: #000004;
  margin: 16px 0 0 0;
  padding: 0;
}

.mobile-modal-video {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 75%;
  height: 75%;
  transform: translate(-50%, -60%);
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.6);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 16px;
}

.mobile-modal-video > iframe {
  width: 100%;
  height: 100%;
}

.mobile-modal-video > p {
  width: 100%;
  font-family: 'Mukta Vaani';
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 100%;
  display: flex;
  align-items: center;
  color: #000004;
  margin: 16px 0 0 0;
}

.mobile-modal-video > video {
  width: 100%;
  height: 100%;
}
