.background > .brightness-layer {
  width: 100%;
  height: 100%;
  background: rgba(35, 35, 35, 0.5);
  z-index: 1;
}

.logo {
  width: 295px;
  height: auto;
  z-index: 1;
}

.landscape,
.portrait {
  height: calc(var(--vh, 1vh) * 100);
  width: calc(var(--vw, 1vw) * 100);
  display: flex;
  position: fixed;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.landscape > .background,
.portrait > .background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.portrait > .background > img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: auto;
  height: 100%;
}

.landscape > .background > img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
  height: auto;
}

.landscape > .explore-btn,
.portrait > .explore-btn {
  z-index: 1;
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height, or 100% */

  text-align: center;
  letter-spacing: 0.0125em;
  text-transform: uppercase;

  /* white */

  color: #ffffff;

  background: rgb(var(--main-color));
  border-radius: 5px;

  padding: 10px 16px;
  margin-top: calc(var(--vh, 1vh) * 8.9);
}

.landscape > .moreInfo,
.portrait > .moreInfo {
  z-index: 1;
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height, or 100% */

  text-align: center;
  letter-spacing: 0.0125em;
  text-transform: uppercase;

  /* white */

  color: #ffffff;
  margin-top: calc(var(--vh, 1vh) * 6.4);
}

.landscape > .moreInfoContent > .contentWrapper > .poweredBy,
.portrait > .moreInfoContent > .contentWrapper > .poweredBy {
  position: absolute;
  bottom: 16px;
  right: 16px;
  z-index: 1;
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 9.24848px;
  line-height: 13px;
  /* identical to box height */

  text-transform: lowercase;

  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.landscape > .moreInfoContent > .contentWrapper > .poweredBy > svg,
.portrait > .moreInfoContent > .contentWrapper > .poweredBy > svg {
  width: 109px;
  height: auto;
  margin-top: 6px;
}

.landscape > .moreInfoContent,
.portrait > .moreInfoContent {
  position: fixed;
  top: 0;
  left: 0;
  width: calc(var(--vw, 1vw) * 100);
  height: calc(var(--vh, 1vh) * 100);
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 3;
  opacity: 0;
  pointer-events: none;
}

.landscape > .moreInfoContent > .contentWrapper,
.portrait > .moreInfoContent > .contentWrapper {
  z-index: 1;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(var(--main-color));
  width: 90vw;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 24px;
  border-radius: 5px;
}

.portrait > .moreInfoContent > .contentWrapper {
  height: 50vh;
}

.landscape > .moreInfoContent > .contentWrapper > svg,
.portrait > .moreInfoContent > .contentWrapper > svg {
  position: absolute;
  right: 16px;
  top: 16px;
  width: 24px;
  height: 24px;
}

.landscape > .moreInfoContent > .contentWrapper > svg > *,
.portrait > .moreInfoContent > .contentWrapper > svg > * {
  fill: #ffffff;
}

.landscape > .moreInfoContent > .contentWrapper > h1,
.portrait > .moreInfoContent > .contentWrapper > h1 {
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.0015em;

  /* branco */

  color: #ffffff;
  margin: 0 0 24px 0;
}

.landscape > .moreInfoContent > .contentWrapper > p,
.portrait > .moreInfoContent > .contentWrapper > p {
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.005em;
  color: #ffffff;
  margin: 0 0 16px 0;
  white-space: pre-wrap;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.landscape > .moreInfoContent > .contentWrapper > p > svg,
.portrait > .moreInfoContent > .contentWrapper > p > svg {
  width: 24px;
  height: 24px;
}

.landscape > .moreInfoContent > .contentWrapper > p > svg > *,
.portrait > .moreInfoContent > .contentWrapper > p > svg > * {
  fill: #ffffff;
}

.portrait-links {
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow: scroll;
}

.portrait-links::-webkit-scrollbar {
  width: 0; /* width of the entire scrollbar */
  height: 0;
}

.portrait-links > svg {
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 1;
  width: 24px;
  height: 24px;
  filter: drop-shadow(0px 1.6px 2.4px #000000);
}

.portrait-links > .wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.portrait-links > .wrapper > .img-link {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  height: 255px;
  width: calc(var(--vw, 1vw) * 100);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 16px;
}

.portrait-links > .wrapper > .img-link > h1 {
  z-index: 1;
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 100%;
  /* or 125% */

  /* white */

  color: #ffffff;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.85);
  margin: 8px 0 0 0;
}

.portrait-links > .wrapper > .img-link > p,
.portrait-links > .wrapper > .img-link > div > p {
  z-index: 1;
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  /* or 150% */

  letter-spacing: 0.005em;

  /* branco */

  color: #ffffff;
  text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.85);
  width: 290px;
  margin: 0;
}

.landscape-links {
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  overflow: scroll;
  background-color: #25282e;
}

.landscape-links::-webkit-scrollbar {
  width: 0; /* width of the entire scrollbar */
  height: 0;
}

.landscape-links > svg {
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 1;
  width: 24px;
  height: 24px;
  filter: drop-shadow(0px 1.6px 2.4px #000000);
}

.landscape-links > .wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #161616;
}

.landscape-links > .wrapper > .img-link {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  width: 420px;
  height: calc(var(--vh, 1vh) * 100);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 32px 16px;
  margin-right: 10px;
}

.landscape-links > .wrapper > .img-link > h1 {
  z-index: 1;
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 100%;
  /* or 125% */

  /* white */

  color: #ffffff;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.85);
  margin: 8px 0 0 0;
}

.landscape-links > .wrapper > .img-link > p,
.landscape-links > .wrapper > .img-link > div > p {
  z-index: 1;
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  /* or 150% */

  letter-spacing: 0.005em;

  /* branco */

  color: #ffffff;
  text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.85);
  width: 290px;
  margin: 0;
}

.landing-page-mobile {
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}

.landing-page-mobile > .background-mobile {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: auto;
}

.landing-page-mobile > .block-mobile {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: all;
  z-index: 1;
  background: #252525;
  opacity: 0.2;
}

.landing-page-mobile > .val-logo-mobile {
  position: fixed;
  top: 20px;
  left: 20px;
  width: 120px;
  height: auto;
  z-index: 1;
}

.landing-page-mobile > .start-container-mobile {
  width: 100%;
  position: fixed;
  top: 75%;
  transform: translate(0, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.landing-page-mobile > .start-container-mobile > .start {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  color: rgb(var(--main-color));
  background: rgba(255, 255, 255, 0.3);
  width: 200px;
  height: 50px;
  border-radius: 0;
  cursor: pointer;
  border: 1px solid rgb(var(--main-color));
  letter-spacing: 2px;
  margin-bottom: 20px;
}

.landing-page-mobile > .mb-logo-mobile {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 34px;
  height: auto;
  z-index: 1;
}

/*landscape*/
.landing-page-mobile-l {
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}

.landing-page-mobile-l > .background-mobile-l {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: auto;
  width: 100%;
}

.landing-page-mobile-l > .block-mobile-l {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: all;
  z-index: 1;
  background: #252525;
  opacity: 0.2;
}

.landing-page-mobile-l > .val-logo-mobile-l {
  position: fixed;
  top: 20px;
  left: 20px;
  width: 120px;
  height: auto;
  z-index: 1;
}

.landing-page-mobile-l > .start-container-mobile-l {
  width: 100%;
  position: fixed;
  top: 75%;
  transform: translate(0, -50%);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.landing-page-mobile-l > .start-container-mobile-l > .start {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  color: rgb(var(--main-color));
  background: rgba(255, 255, 255, 0.3);
  width: 200px;
  height: 50px;
  border-radius: 0;
  cursor: pointer;
  border: 1px solid rgb(var(--main-color));
  letter-spacing: 2px;
}

.landing-page-mobile-l > .start-container-mobile-l > .start.en {
  margin-right: 10px;
}

.landing-page-mobile-l > .start-container-mobile-l > .start.de {
  margin-left: 10px;
}

.landing-page-mobile-l > .mb-logo-mobile-l {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 34px;
  height: auto;
  z-index: 1;
}

.landing-page-mob {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.landing-page-mob > .bg-video-mob {
  height: 100%;
  width: auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

.landing-page-mob > .pm-focus-mob {
  height: 100%;
  width: 100%;
  position: fixed;
  background: rgba(35, 35, 35, 0.5);
  z-index: 1;
}

.landing-page-mob > .pm-logo-mob {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -75px);
  width: 300px;
  z-index: 2;
}

.landing-page-mob > .pm-button-mob {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, +25px);
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 215px;
  padding: 10px 16px 9px 16px;
  cursor: pointer;
  z-index: 2;
}

.landing-page-mob > .pm-button-mob > p {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  text-align: center;
  letter-spacing: 0.0125em;
  text-transform: uppercase;
  color: #ffffff;
  margin: 0;
  padding: 0;
  transition: color 0.3s ease-in-out;
}

.landing-page-mob > div.pm-button-mob > svg {
  width: 16px;
  height: auto;
  margin-left: 8px;
}

.landing-page-mob > div.pm-button-mob > svg > * {
  fill: #ffffff;
  transition: fill 0.3s ease-in-out;
}

.landing-page-mob > .pm-powered-mob {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.landing-page-mob > .pm-powered-mob > p {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 8px;
  line-height: 135%;
  text-transform: lowercase;
  color: #ffffff;
  margin: 0 0 8px 0;
  padding: 0;
}

.landing-page-mob > .pm-powered-mob > svg {
  width: 100px;
}

.landing-page-mob > .pm-more-mob {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, +85px);
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  text-transform: uppercase;
  color: #ffffff;
  margin: 0;
  padding: 0;
  z-index: 2;
}

.pm-more-info-mob {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  z-index: 3;
  background: rgb(var(--main-dark));
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  padding: 44px 20vw;
}

.pm-more-info-mob > .pm-close-mob {
  position: absolute;
  right: 20px;
  top: 20px;
  width: 24px;
  height: 24px;
}

.pm-more-info-mob > .pm-close-mob > * {
  fill: #ffffff;
}

.pm-more-info-mob > p {
  font-family: Nunito Sans;
  font-size: 16px;
  line-height: 135%;
  padding: 0;
  margin: 0;
  color: #ffffff;
}
