.modal-link {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.6);
  border-radius: 5px;
  overflow: hidden;
}

.modal-link > .link-title {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.modal-link > .link-title > h1 {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: #ffffff;
  margin: 0;
  padding: 0;
  padding-left: 16px;
  background: rgb(var(--main-color));
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
}

.modal-link > .link-title > .link-close {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: rgb(var(--main-dark));
}

.modal-link > .link-title > .link-close > svg {
  width: 24px;
  height: 24px;
}

.modal-link > .link-title > .link-close > svg > * {
  fill: #ffffff;
}

.modal-link > .content-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #ffffff;
}

.modal-link > .content-link > .link-img {
  max-width: 40vw;
  width: auto;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 16px 8px 16px 16px;
}

.modal-link > .content-link > .link-img > img {
  /*position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);*/
  height: 50vh;
  width: auto;
}

.modal-link > .content-link > .link-text {
  width: 15vw;
  height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  margin: 16px 16px 16px 8px;
}

.modal-link > .content-link > .link-text > h1 {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 27px;
  color: #000004;
  margin: 0 0 16px 0;
  padding: 0;
}

.modal-link > .content-link > .link-text > p {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.005em;
  color: #000004;
  margin: 0 0 16px 0;
  padding: 0;
}

.modal-link > .content-link > .link-text > div {
  border: 2px solid rgb(var(--main-color));
  box-sizing: border-box;
  border-radius: 5px;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.0125em;
  text-transform: uppercase;
  color: rgb(var(--main-color));
  padding: 10px 16px;
  cursor: pointer;
}
