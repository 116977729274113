.nadirHS {
  visibility: visible;
  width: 900px;
  height: 900px;
  background: #000;
  border-radius: 50%;
}

#nadirHS {
  border: 4px green solid;
  border-radius: 50%;
}
