.new-mob-nav-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 56px;
  background: rgba(255, 255, 255, 0.9);
}

.new-mob-nav-bar > h1 {
  padding: 0 0 0 16px;
  width: 70%;
  font-family: Mukta Vaani;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.005em;
  color: #101010;
}

.new-mob-nav-bar > svg {
  width: 24px;
  height: 24px;
}

.new-mob-nav-bar > svg:last-child {
  margin-right: 16px;
}

.new-mob-nav-bar > svg > * {
  fill: #808080;
}

.new-mob-nav-bar > svg.active > * {
  fill: rgb(var(--main-color));
}

.new-mob-thumbnails-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100vh - 56px);
  background: rgba(255, 255, 255, 1);
  z-index: 98;
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  overflow: auto;
}

.new-mob-thumbnails-menu > .new-thumb {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  width: 142px;
  overflow: hidden;
  border-radius: 10px;
  margin-right: 8px;
  margin-bottom: 8px;
}

.new-mob-thumbnails-menu > .new-thumb > img {
  height: 100%;
  width: auto;
}

.new-mob-thumbnails-menu > .new-thumb > p {
  height: 80%;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  font-family: Merriweather Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 125%;
  margin: 0;
  padding: 0 0 10px 10px;
  color: #ffffff;
  background: linear-gradient(180deg, rgba(81, 81, 81, 0) 0%, #000000 100%);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
}

.new-mob-thumbnails-menu > .new-thumb > .border {
  position: absolute;
  width: 99%;
  height: 99%;
  border: 4px solid rgb(var(--main-color));
}

.new-mob-info {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100vh - 56px);
  background: rgba(var(--main-dark), 0.9);
  z-index: 98;
  padding: 90px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow: auto;
}

.new-mob-info > p {
  font-family: Nunito Sans;
  font-size: 18px;
  line-height: 34px;
  padding: 0;
  margin: 0;
  color: #ffffff;
}
