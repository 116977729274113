.new-bar {
  position: fixed;
  bottom: 64px;
  left: 50%;
  transform: translate(-50%);
  z-index: 99;
  width: 176px;
  height: 64px;
  background: rgba(255, 255, 255, 0.85);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px;
  transition: all 0.3s ease-in-out;
}

.new-bar.open-thumbnails {
  bottom: 172px;
}

.new-bar > .new-info,
.new-bar > .new-play-pause,
.new-bar > .new-thumbnails {
  width: 48px;
  height: 48px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.new-bar > .new-play-pause {
  background: #222222;
}

.new-bar > .new-info > svg,
.new-bar > .new-play-pause > svg,
.new-bar > .new-thumbnails > svg {
  width: 28px;
  height: 28px;
}

.new-bar > .new-info > svg > *,
.new-bar > .new-thumbnails > svg > * {
  fill: #222222;
}

.new-bar > .new-play-pause > svg > * {
  fill: #ffffff;
}

.new-bar > .new-info:hover,
.new-bar > .new-info.active:hover,
.new-bar > .new-thumbnails:hover {
  cursor: pointer;
  background: #bcbcbc;
}

.new-bar > .new-play-pause:hover {
  cursor: pointer;
}

.new-bar > .new-info.active,
.new-bar > .new-thumbnails.active {
  background: #dfdfdf;
}

.new-bar > .new-info.inactive {
  cursor: default;
  pointer-events: none;
}

.new-bar > .new-info.inactive > svg > * {
  fill: #949494;
}

.new-nav-background {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 89;
}

.new-info-p {
  position: fixed;
  left: 50%;
  bottom: 136px;
  transform: translate(-50%);
  max-width: 592px;
  padding: 24px;
  background: rgba(255, 255, 255, 0.75);
  box-shadow: 0px 3px 56px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(15px);
  border-radius: 5px;
  pointer-events: none;
  opacity: 0;
  z-index: 90;
  transition: all 0.3s ease-in-out;
}

.new-info-p.open-thumbnails {
  bottom: 244px;
}

.new-info-p > p {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: flex-end;
  color: #0e0e0e;
  padding: 0;
  margin: 0;
}

.new-info-p.active {
  opacity: 1;
}

.new-thumbnails-menu {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: 0;
  background: rgba(255, 255, 255, 0.85);
  box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.2);
  z-index: 90;
  transition: height 0.3s ease-in-out;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  overflow: auto;
}

.new-thumbnails-menu.active {
  height: 148px;
}

.new-thumbnail-item {
  flex: 0 0 162px;
  height: 108px;
  margin-right: 16px;
  overflow: hidden;
  cursor: pointer;
}

.new-thumbnail-item > .border {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 3px solid rgb(var(--main-color));
}

.new-thumbnail-item:first-child {
  margin-left: 40px;
}

.new-thumbnail-item:last-child {
  margin-right: 40px;
}

.new-thumbnail-item > img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  height: 100%;
}

.new-thumbnail-item > p {
  height: 80%;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  font-family: Merriweather Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 125%;
  margin: 0;
  padding: 0 0 10px 10px;
  color: #ffffff;
  background: linear-gradient(180deg, rgba(81, 81, 81, 0) 0%, #000000 100%);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
}
