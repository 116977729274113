@font-face {
  font-family: 'Myriad Pro';
  src: url(../fonts/MYRIADPRO-REGULAR.OTF) format('truetype');
}

body {
  background: #000000;
}

.landing-page {
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}

.landing-page > img.pm-bg {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  height: auto;
  z-index: 0;
}

.landing-page > video.bg-video {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  height: auto;
  z-index: 0;
}

.landing-page > div.pm-focus {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(35, 35, 35, 0.6);
  z-index: 0;
}

.landing-page > img.pm-logo {
  width: 30vw;
  height: auto;
  z-index: 1;
  position: fixed;
  top: 21vh;
  left: 9.375vw;
}

.landing-page > h1.pm-title {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 2vw;
  line-height: 135%;
  padding: 0;
  margin: 2.96vh 0 0 0;
  color: #ffffff;
  width: 32vw;
  z-index: 1;
}

.landing-page > p.pm-desc {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 0.938vw;
  line-height: 133%;
  letter-spacing: 0.005em;
  margin: 1.48vh 0 0 0;
  padding: 0;
  color: #ffffff;
  width: 32vw;
  z-index: 1;
  position: fixed;
  top: 48vh;
  left: 9.375vw;
}

.landing-page > div.pm-explore {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 0.8vw;
  line-height: 225%;
  text-transform: uppercase;
  color: #ffffff;
  border-bottom: 1px solid #ffffff;
  width: 15vw;
  position: fixed;
  z-index: 1;
  top: 40vh;
  left: 58vw;
}

.landing-page > div.pm-buttons {
  position: fixed;
  top: 48vh;
  left: 58vw;
  width: 15vw;
}

.landing-page > div.pm-buttons > div.pm-btn {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 300;
  font-size: 1.8vw;
  line-height: 150%;
  display: flex;
  align-items: center;
  letter-spacing: 0.0025em;
  color: #ffffff;
  margin-bottom: 2vw;
  transition: all 0.2s ease;
  opacity: 0.8;
}

.landing-page > div.pm-buttons > div.pm-btn:hover {
  font-weight: 800;
  opacity: 1;
  cursor: pointer;
}

.landing-page > img.pm-partner {
  position: fixed;
  bottom: 4.63vh;
  right: 8.33vw;
  z-index: 1;
  width: 6vw;
  height: auto;
}

.landing-page > div.pm-button {
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /*width: 10.938vw;*/
  padding: 10px 16px 9px 16px;
  cursor: pointer;
  margin-top: 4vh;
  transition: background-color 0.3s ease-in-out;
  z-index: 1;
}

.landing-page > div.pm-button:hover {
  background-color: #ffffff;
}

.landing-page > div.pm-button > p {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 0.833vw;
  line-height: 100%;
  text-align: center;
  letter-spacing: 0.0125em;
  text-transform: uppercase;
  color: #ffffff;
  margin: 0;
  padding: 0;
  transition: color 0.3s ease-in-out;
}

.landing-page > div.pm-button:hover > p {
  color: #101010;
}

.landing-page > div.pm-button > svg {
  width: 1.25vw;
  height: auto;
  margin-left: 8px;
}

.landing-page > div.pm-button > svg > * {
  fill: #ffffff;
  transition: fill 0.3s ease-in-out;
}

.landing-page > div.pm-button:hover > svg > * {
  fill: #101010;
}

.landing-page > div.pm-powered {
  position: fixed;
  bottom: 4.63vh;
  right: 8.33vw;
  z-index: 1;
}

.landing-page > div.pm-powered > p {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 0.729vw;
  line-height: 135%;
  text-transform: lowercase;
  color: #ffffff;
  margin: 0 0 0.74vh 0;
  padding: 0;
}

.landing-page > div.pm-powered > svg {
  width: 8.594vw;
  height: auto;
}

.landing-page > img.background {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: auto;
  width: 100%;
}

.landing-page > img.val-logo {
  position: fixed;
  top: 40px;
  left: 40px;
  width: 195px;
  height: auto;
  z-index: 1;
}

.landing-page > .mb-logo {
  position: fixed;
  bottom: 40px;
  right: 40px;
  width: 60px;
  height: auto;
  z-index: 1;
}

.landing-page > .pb {
  position: fixed;
  bottom: 80px;
  left: 75vw;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  text-transform: lowercase;
  color: #ffffff;
  z-index: 1;
}

.landing-page > .block {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: all;
  z-index: 1;
  background: #252525;
  opacity: 0.2;
}

.landing-page > .title {
  position: fixed;
  top: 35%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 96px;
  line-height: 115px;
  text-transform: uppercase;
  color: #ffffff;
  z-index: 1;
}

.landing-page > .start-container {
  width: 100%;
  position: fixed;
  top: 75%;
  transform: translate(0, -50%);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.landing-page > .start-container > .start {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  color: rgb(var(--main-color));
  background: rgba(255, 255, 255, 0.3);
  width: 10vw;
  height: 8vh;
  border-radius: 0;
  cursor: pointer;
  border: 1px solid rgb(var(--main-color));
  letter-spacing: 2px;
}

.landing-page > .start-container > .start:hover {
  background: rgb(95, 35, 72);
  color: #ffffff;
}

.landing-page > .start-container > .start.en {
  margin-right: 20px;
}

.landing-page > .start-container > .start.de {
  margin-left: 20px;
}

.landing-page > .center-container > .cov-container {
  flex: 3;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.landing-page > .center-container > .cov-container > .cov {
  width: 15vw;
  height: auto;
}

.landing-page > .center-container {
  position: fixed;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 100vw;
  padding: 0 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.landing-page > .center-container > .info {
  flex: 4;
  font-family: Mukta Vaani;
}

.landing-page > .center-container > .info > h1 {
  margin-top: 0;
  font-size: 2.3vw;
  text-shadow: 4px 4px 7px black;
}

.landing-page > .center-container > .info > p {
  width: 75%;
  font-size: 1.5vw;
  text-shadow: 3px 3px 6px black;
}

.landing-page > .center-container > .info > .buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 75%;
}

.landing-page > .center-container > .info > .buttons-container > .start {
  width: 35%;
  font-size: 1.1vw;
  line-height: 210%;

  color: #fff;

  background: #f5a802;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  cursor: pointer;
  border: none;
}

.landing-page > .center-container > .info > .buttons-container > .start:hover {
  background-color: #ecbc55;
}

.landing-page > .logo {
  position: absolute;
  left: 50%;
  bottom: 3%;
  transform: translateX(-50%);
  width: 15%;
  height: auto;
}

.landing-page > .sponsor {
  position: absolute;
  bottom: 3%;
  right: 4%;
  width: 5vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.landing-page > .sponsor > p {
  flex: 1;
  font-family: Mukta Vaani;
  font-size: 1vw;
  line-height: 89%;
  text-align: center;
  color: #ffffff;
}

.landing-page > .sponsor > img {
  flex: 1;
  width: 100%;
}

.stop-sharing-indicator {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  border: 5px solid #92de47;
  pointer-events: none;
}

.remote-indicator {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  border: 5px solid #d92626;
  pointer-events: all;
}

.stop-sharing-indicator > .ss-container {
  position: fixed;
  left: 50%;
  bottom: 10px;
  transform: translate(-50%, 0);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.stop-sharing-indicator > .ss-container > .ss-white {
  background: #ffffff;
  opacity: 0.85;
  border-radius: 5px 0 0 5px;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.0015em;
  color: #000004;
  padding: 8px 16px;
}

.stop-sharing-indicator > .ss-container > .ss-red {
  background: #d92626;
  opacity: 0.85;
  border-radius: 0 5px 5px 0;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.0015em;
  color: #ffffff;
  padding: 2px 16px 2px 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  pointer-events: all;
  cursor: pointer;
}

.limit-reached {
  position: fixed;
  width: 100%;
  height: 100%;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 12px;
  letter-spacing: 0.0015em;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.block-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(50, 50, 50, 0.6);
  z-index: 101;
}

.block-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 438px;
  height: 214px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.6);
  border-radius: 4.96681px;
  z-index: 1;
  padding: 16px;
}

.block-container > svg {
  width: 70px;
  height: 70px;
}

.block-container > svg > * {
  fill: #000000;
}

.block-container > p {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */
  text-align: start;
  letter-spacing: 0.005em;

  color: #000000;
  width: 100%;
  margin: 0;
}

.block-container > p > svg {
  width: 24px;
  height: 24px;
  margin-left: 4px;
  vertical-align: text-bottom;
}

.block-container > p > svg > * {
  fill: #000000;
}

.block-container > p > strong {
  font-family: Nunito Sans;
  font-size: 16px;
  line-height: 20px;
}

.block-container > .buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.block-container > .buttons > .cancel {
  cursor: pointer;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height, or 100% */

  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.0125em;
  text-transform: uppercase;

  color: rgb(var(--main-color));
  width: 116px;
  height: 36px;
}

.lobby-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  background: rgba(50, 50, 50, 0.9);
  pointer-events: all;
}
