.form-page {
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}

.form-page > img.f-pm-bg {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  height: auto;
  z-index: 0;
}

.form-page > div.f-pm-focus {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(35, 35, 35, 0.6);
  z-index: 0;
}

.form-page > img.f-pm-logo {
  width: 30vw;
  height: auto;
  z-index: 1;
  position: fixed;
  top: 21vh;
  left: 9.375vw;
  cursor: pointer;
}

.form-page > h1.f-pm-title {
  position: fixed;
  left: 15vw;
  top: 43vh;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 2.5vw;
  line-height: 135%;
  padding: 0;
  margin: 2.96vh 0 0 0;
  color: #ffffff;
  width: 32.292vw;
  z-index: 1;
}

.form-page > p.f-pm-desc {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 0.938vw;
  line-height: 133%;
  letter-spacing: 0.005em;
  margin: 1.48vh 0 0 0;
  padding: 0;
  color: #ffffff;
  width: 32vw;
  z-index: 1;
  position: fixed;
  top: 48vh;
  left: 9.375vw;
}

.form-page > img.f-pm-partner {
  position: fixed;
  bottom: 4.63vh;
  right: 8.33vw;
  z-index: 1;
  width: 6vw;
  height: auto;
}

.form-page > div.f-pm-buttons {
  position: fixed;
  left: 9.375vw;
  top: 62vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.form-page > div.f-pm-buttons > div.f-pm-button {
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /*width: 10.938vw;*/
  padding: 10px 16px 9px 16px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  z-index: 1;
  margin-right: 28px;
}

.form-page > div.f-pm-buttons > div.f-pm-button:hover {
  background-color: #ffffff;
}

.form-page > div.f-pm-buttons > div.f-pm-button > p {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 0.833vw;
  line-height: 100%;
  text-align: center;
  letter-spacing: 0.0125em;
  text-transform: uppercase;
  color: #ffffff;
  margin: 0;
  padding: 0;
  transition: color 0.3s ease-in-out;
}

.form-page > div.f-pm-buttons > div.f-pm-button:hover > p {
  color: #101010;
}

.form-page > div.f-pm-buttons > div.f-pm-button > svg {
  width: 1.25vw;
  height: auto;
  margin-left: 8px;
}

.form-page > div.f-pm-buttons > div.f-pm-button > svg > * {
  fill: #ffffff;
  transition: fill 0.3s ease-in-out;
}

.form-page > div.f-pm-buttons > div.f-pm-button:hover > svg > * {
  fill: #101010;
}

.form-page > div.f-pm-powered {
  position: fixed;
  bottom: 4.63vh;
  right: 8.33vw;
  z-index: 1;
}

.form-page > div.f-pm-powered > p {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 0.729vw;
  line-height: 135%;
  text-transform: lowercase;
  color: #ffffff;
  margin: 0 0 0.74vh 0;
  padding: 0;
}

.form-page > div.f-pm-powered > svg {
  width: 8.594vw;
  height: auto;
}

div.contact-info {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 0.8vw;
  line-height: 225%;
  text-transform: uppercase;
  color: #ffffff;
  border-bottom: 1px solid #ffffff;
  width: 15vw;
  position: fixed;
  z-index: 1;
  top: 40vh;
  left: 58vw;
  z-index: 1;
}

div.email-info {
  position: fixed;
  top: 53vh;
  left: 58vw;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 300;
  font-size: 0.938vw;
  line-height: 133%;
  letter-spacing: 0.005em;
  margin: 1.48vh 0 0 0;
  padding: 0;
  color: #ffffff;
  z-index: 1;
  text-transform: lowercase;
  cursor: pointer;
}

div.person-info {
  position: fixed;
  top: 48vh;
  left: 58vw;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 300;
  font-size: 0.938vw;
  line-height: 133%;
  letter-spacing: 0.005em;
  margin: 1.48vh 0 0 0;
  padding: 0;
  color: #ffffff;
  z-index: 1;
}

div.phone-info {
  position: fixed;
  top: 58vh;
  left: 58vw;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 300;
  font-size: 0.938vw;
  line-height: 133%;
  letter-spacing: 0.005em;
  margin: 1.48vh 0 0 0;
  padding: 0;
  color: #ffffff;
  z-index: 1;
}
