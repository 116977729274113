.left-container {
  position: fixed;
  top: 50%;
  left: 3.13vw;
  transform: translate(0, -50%) rotate(0deg);
  z-index: 90;
  width: 64px;
  height: 64px;
}

.mobile.left-container,
.mobile.left-container > .left-arrow,
.mobile.right-container,
.mobile.right-container > .right-arrow {
  width: 40px;
  height: 40px;
}

.left-container > .left-arrow {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.7));
  width: 64px;
  height: 64px;
  opacity: 0.85;
  z-index: 1;
  transition: opacity 0.3s ease-in-out;
}

.back-oval {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 64px;
  height: 64px;
  z-index: 0;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.right-container {
  position: fixed;
  top: 50%;
  right: 3.13vw;
  transform: translate(0, -50%) rotate(180deg);
  z-index: 90;
  width: 64px;
  height: 64px;
}

.right-arrow {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 90;
  filter: drop-shadow(0px -2px 1px rgba(0, 0, 0, 0.7));
  width: 64px;
  height: 64px;
  opacity: 0.85;
  transition: opacity 0.3s ease-in-out;
  z-index: 1;
}

.left-container:hover > .back-oval,
.right-container:hover > .back-oval,
.left-container:hover > .left-arrow,
.right-container:hover > .right-arrow {
  cursor: pointer;
  opacity: 1;
}

.left-arrow > path,
.right-arrow > path {
  fill: #ffffff;
}

.left-arrow > *,
.right-arrow > * {
  filter: none;
}
