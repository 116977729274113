/*@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://example.com/MaterialIcons-Regular.eot); /* For IE6-8 */
/* src: local('Material Icons'), local('MaterialIcons-Regular'),
    url(https://example.com/MaterialIcons-Regular.woff2) format('woff2'),
    url(https://example.com/MaterialIcons-Regular.woff) format('woff'),
    url(https://example.com/MaterialIcons-Regular.ttf) format('truetype');
}*/

.custom-link-hotspot {
  position: absolute;
  width: 55px;
  height: 55px;
  cursor: pointer;
  opacity: 0.9;
  transition: opacity 0.3s ease;
}

.custom-link-hotspot.hovered {
  transition: all 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  width: 65px;
  height: 65px;
}

.custom-link-hotspot > svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 55px;
  height: 55px;
}

.custom-link-hotspot.hovered > svg {
  transition: all 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  width: 65px;
  height: 65px;
}

.custom-link-hotspot:hover {
  opacity: 1;
}

.custom-link-hotspot > svg > * {
  fill: #ffffff;
  filter: none;
}

.custom-link-hotspot > .material-icons {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 24px;
  height: 24px;
  font-size: 24px;
}

.custom-link-hotspot.hovered > .material-icons {
  transition: all 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  width: 34px;
  height: 34px;
  font-size: 34px;
}

.material-icons {
  color: rgb(var(--main-color));
}
